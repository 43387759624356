import React from 'react'
import Linkify from 'linkifyjs/react'
import avatar from './4help.png'

export default class Message extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedOption: false
    }
  }

  render () {
    const messageType = this.props.type
    const role = this.props.role
    const mainType = this.props.mainType
    const options = this.props.options

    const agentHas = this.props.name + ' has'
    const customerHas = 'You have'

    let personHas

    if (role === 'CUSTOMER') {
      personHas = customerHas
    }

    if (role === 'AGENT') {
      personHas = agentHas
    }
    if (mainType === 'ATTACHMENT') {
      let divClass = 'message-other'
      let fromName = this.props.name
      let img = null
      let divider = null

      if (this.props.role === 'CUSTOMER') {
        divClass = 'message-customer'
      } else if (this.props.role === 'SYSTEM') {
        img = <img src={avatar} alt='Avatar' />
        divider = <div class='divider2' />
        divClass = 'message-system'
        fromName = 'ITS Service Center'
      } else if (this.props.role === 'AGENT') {
        divClass = 'message-agent'
      }
      return (
        <div className={divClass} role='listitem'>
          <div className='from'><span className='label'>From: </span>{img}{divider}{fromName}</div>
          <div className='message'><span className='label'>Message: </span><a href={this.props.attachUrl} download={this.props.attachmentName}>{this.props.attachmentName}</a></div>
          <div className='sent'><span className='label'>Sent: </span>{this.props.time}</div>
          <hr />
        </div>
      )

    // event messages
    } else if (mainType === 'EVENT') {
      let message = ''

      if (messageType.includes('joined')) {
        message = personHas + ' joined the chat.'
      } else if (messageType.includes('left')) {
        message = personHas + ' left the chat.'
      } else if (messageType.includes('succeed')) {
        message = 'The Transfer Succeeded.'
      } else if (messageType.includes('failed')) {
        message = 'The Transfer Failed.'
      } else if (messageType.includes('ended')) {
        message = 'The chat has ended.'
      }

      return (
        <div className='message-event' role='listitem'>
          <div className='message'><span className='label'>event </span>{message}</div>
          <div className='sent'><span className='label'>Sent: </span>{this.props.time}</div>
          <hr />
        </div>
      )
    } else if (messageType.includes('application/vnd.amazonaws.connect.message.interactive')) {
      let divClass = 'message-other'
      let fromName = this.props.name
      let img = null
      let divider = null

      if (this.props.role === 'CUSTOMER') {
        divClass = 'message-customer'
      } else if (this.props.role === 'SYSTEM') {
        img = <img src={avatar} alt='Avatar' />
        divider = <div class='divider2' />
        divClass = 'message-system'
        fromName = 'ITS Service Center'
      } else if (this.props.role === 'AGENT') {
        divClass = 'message-agent'
      }

      return (

        <div className={divClass} role='listitem'>
          <div className='from'><span className='label'>From: </span>{img}{divider}{fromName}</div>
          <div className='message'><span className='label'>Message: </span>Hello. Thank you for contacting the ITS Service Center. Please select one of the options below to get started.</div>

          <div className='list'><span className='label'>Selection:  </span>
            <div className='listButtons'>{options}</div>
          </div>
          <div className='sent'><span className='label'>Sent: </span>{this.props.time}</div>
          <hr />
        </div>
      )
    } else if (this.props.content.includes('The chat has ended.')) {
      const message = this.props.content

      return (
        <div className='message-event' role='listitem'>
          <div className='message'>{message}</div>
          <div className='sent'><span className='label'>Sent: </span>{this.props.time}</div>
          <hr />
        </div>
      )
    } else {
      // quick out if this message should be hidden
      if (this.props.content.includes('<sent to agent only>')) {
        return null
      }

      // standard message bubble
      let divClass = 'message-other'
      let fromName = this.props.name
      let img = null
      let divider = null

      if (this.props.role === 'CUSTOMER') {
        divClass = 'message-customer'
      } else if (this.props.role === 'SYSTEM') {
        img = <img src={avatar} alt='Avatar' />
        divider = <div class='divider2' />
        divClass = 'message-system'
        fromName = 'ITS Service Center'
      } else if (this.props.role === 'AGENT') {
        divClass = 'message-agent'
      }

      return (
        <div className={divClass} role='listitem'>
          <div className='from'><span className='label'>From: </span>{img}{divider}{fromName}</div>
          <div className='message'><span className='label'>Message: </span><Linkify options={{ target: '_blank' }}>{this.props.content}</Linkify></div>
          <div className='sent'><span className='label'>Sent: </span>{this.props.time}</div>
          <hr />
        </div>
      )
    }
  }
}
