// its styles from the portal
import './its-css/common.css'
import './its-css/public.css'
import './its-css/mobile.css'
import './its-css/its-styles.css'

import 'font-awesome/css/font-awesome.css'

// React
import React from 'react'
import ReactDOM from 'react-dom'

// Root App
import { ChatApp } from './ChatApp'
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <ChatApp />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
