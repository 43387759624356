import React from 'react'
import axios from 'axios'
import { animateScroll } from 'react-scroll'
import { DateTime } from 'luxon'
import 'amazon-connect-chatjs'
import * as chatConfig from './connect-config.ts'
import platform from 'platform'
import { BsPaperclip } from 'react-icons/bs'
import { IconContext } from 'react-icons'
import Button from 'react-bootstrap/Button'

// custom CSS for the app
import './ChatApp.css'
// import { sampleMessages } from './SampleMessages.js'
import Message from './Message'

export class ChatApp extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      state: 'initial',

      isLoaded: false, // Amazon ChatJS
      name: '', // 'Jim',
      uniqname: '', // 'jamesez',
      messages: [], // sampleMessages,
      message: '',
      agentIsTyping: false,
      typingTimer: null,
      agentInSession: false,
      endPressed: false,
      rejoinPressed: false,
      agentLeft: false,
      userAgent: null,
      deviceInfo: null,
      sourceIp: null,
      notificationSupported: false,
      isWindowInFocus: true,
      attachUrl: null,
      attachmentId: null,
      attachmentName: null,
      attachmentUploading: false,
      customerNoResponse: false,
      customerOptions: null,
      heartbeatInterval: null
      // clickedOption: false
    }
  }

  componentDidMount () {
    const isLoaded = window.connect && window.connect.ChatSession

    // TODO handle isLoaded as false, show some 'couldn't connect to chat'
    this.setState({ isLoaded: isLoaded })
    const info = platform.parse(this.state.userAgent)
    this.setState({ deviceInfo: info.description })

    // turning these off for now, generates a ton of Safari errors
    // const logger = {
    //   debug: (data) => {
    //     console.debug(data)
    //   },
    //   info: (data) => {
    //     console.info(data)
    //   },
    //   warn: (data) => {
    //     console.warn(data)
    //   },
    //   error: (data) => {
    //     console.error(data)
    //   }
    // }

    const globalConfig = { // required: no. This object defines some config, but all of it is optional.
      loggerConfig: {
        logger: {
          debug: (msg) => console.debug(msg),
          info: (msg) => console.log(msg),
          warn: (msg) => console.warn(msg),
          error: (msg) => console.error(msg)
        },
        level: window.connect.ChatSession.LogLevel.DEBUG // required: no. There are four levels available - DEBUG, INFO, WARN, ERROR. Default is INFO.
      },
      region: chatConfig.awsRegion
    }

    window.connect.ChatSession.setGlobalConfig(globalConfig)
    // this.setupBeforeUnloadListener()
    this.setupBrowserActivity()
  }

  scrollToBottom () {
    animateScroll.scrollToBottom({
      containerId: 'chat-window',
      duration: 0,
      ignoreCancelEvents: true
    })
  };

  setNotifications () {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification')
    } else {
      this.setState({ notificationSupported: true })
      try {
        // eslint-disable-next-line
        Notification.requestPermission().then()
      } catch (e) {
        return false
      }

      return true
    }
  }

  // TODO move these form components down into a subcomponent
  handleFormInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState({ [name]: value })
  }

  // handle start chat button
  handleStartChat = (e) => {
    e.preventDefault()
    if (this.state.rejoinPressed === false) {
      this.setState({ state: 'connecting' })
    }

    // call the API gateway to get a chat session token
    console.log('starting chat')
    axios.post(chatConfig.apiGatewayEndpoint, {
      Attributes: {
        uniqname: this.state.uniqname,
        userAgent: this.state.deviceInfo

      },
      ParticipantDetails: {
        DisplayName: this.state.name
      },
      ContactFlowId: chatConfig.contactFlowId,
      InstanceId: chatConfig.instanceId

    }).then(res => {
      const chatTokenDetails = res.data.data.startChatResult
      console.log(JSON.stringify(chatTokenDetails))

      this.chatSession = window.connect.ChatSession.create({
        chatDetails: chatTokenDetails,
        type: window.connect.ChatSession.SessionTypes.CUSTOMER
      })

      this.chatSession.connect().then((response) => {
        console.log('Success: ' + JSON.stringify(response))
        return response
      })

      this.chatSession.onConnectionEstablished(() => {
        this.setState({ state: 'chatting' })
        console.log('Established')
        this.setNotifications()
        this.chatSound()
      })

      this.chatSession.onEnded(() => {
        console.log(' Ended Chat')
        this.setState({ customerNoResponse: true })
      })

      this.chatSession.onMessage((data) => {
        console.log('Message: ' + JSON.stringify(data.data))
        // interactive messages
        if (data.data.ContentType === 'application/vnd.amazonaws.connect.message.interactive') {
          const arr2 = data.data.Content
          const typer = JSON.parse(arr2)
          const options = typer.data.content.elements

          const finalOptions = options.map((e) => (
            <Button
              key={e.title}
              value={e.title}
              // eslint-disable-next-line
              onClick={this.sendResponse}
            >
              {e.title}
            </Button>)
          )
          const message = {
            attachmentName: null,
            attachmentId: null,
            attachUrl: null,
            options: finalOptions,
            mainType: data.data.Type,
            type: data.data.ContentType,
            role: data.data.ParticipantRole,
            name: data.data.DisplayName,
            content: data.data.Content,
            time: DateTime.fromISO(data.data.AbsoluteTime).toLocaleString(DateTime.DATETIME_FULL)
          }

          this.setState((state) => ({
            agentIsTyping: false,
            messages: state.messages.concat(message)
          }))
          this.scrollToBottom()
          // attachments
        } else if (data.data.Type === 'ATTACHMENT') {
          const arr = data.data.Attachments
          const attachmentId = arr[0].AttachmentId
          const attachmentName = arr[0].AttachmentName

          this.chatSession.downloadAttachment({
            attachmentId: attachmentId
          }).then((res, req) => {
            console.log('Attachment response', res)
            const fileUrl = window.URL.createObjectURL(res)
            console.log('CreateObjectURl', fileUrl)

            const message = {
              attachmentName: attachmentName,
              attachmentId: attachmentId,
              attachUrl: fileUrl,
              options: null,
              mainType: data.data.Type,
              type: data.data.ContentType,
              role: data.data.ParticipantRole,
              name: data.data.DisplayName,
              content: data.data.Content,
              time: DateTime.fromISO(data.data.AbsoluteTime).toLocaleString(DateTime.DATETIME_FULL)
            }
            this.setState({ attachmentUploading: false })

            if (message.role !== 'CUSTOMER') {
              if (this.state.isWindowInFocus === false) {
                this.showNotification(message)
              }
            }
            this.setState((state) => ({
              agentIsTyping: false,
              messages: state.messages.concat(message)
            }))
            this.scrollToBottom()
          })
          // everything else
        } else {
          const message = {
            attachmentName: null,
            attachmentId: null,
            attachUrl: null,
            options: null,
            mainType: data.data.Type,
            type: data.data.ContentType,
            role: data.data.ParticipantRole,
            name: data.data.DisplayName,
            content: data.data.Content,
            time: DateTime.fromISO(data.data.AbsoluteTime).toLocaleString(DateTime.DATETIME_FULL)
          }

          console.log(JSON.stringify(message))
          if (message.type === 'application/vnd.amazonaws.connect.event.participant.joined' && message.role === 'AGENT') {
            this.setState({ agentInSession: true })
            this.chatSession.sendMessage({ message: '<sent to agent only>  Browser and Device Info: ' + this.state.deviceInfo, contentType: 'text/plain' })
          }

          if (message.type === 'application/vnd.amazonaws.connect.event.participant.left' && message.role === 'AGENT') {
            this.setState({ agentInSession: false })
            this.setState({ agentLeft: true })
          }

          if (message.content === '<sent to agent only> Prime Lex Bot.') {
            this.chatSession.sendMessage({ message: '<sent to agent only> help', contentType: 'text/plain' })
          }
          if (message.role !== 'CUSTOMER') {
            if (this.state.isWindowInFocus === false) {
              this.showNotification(message)
            }
          }

          this.setState((state) => ({
            agentIsTyping: false,
            messages: state.messages.concat(message)
          }))
          this.scrollToBottom()
        }
      })

      this.chatSession.onTyping((typingEvent) => {
        // it seems Amazon sends us a new typing message every 10 seconds
        // if we don't get a new typing message in 13 seconds, deactivate the typing flag
        function clearAgentTyping (obj) {
          clearTimeout(obj.state.typingTimer)
          obj.setState({ agentIsTyping: false, typingTimer: null })
        }
        if (typingEvent.data.ParticipantRole === 'AGENT') {
          // reset the timer
          if (this.state.typingTimer !== null) {
            clearTimeout(this.state.typingTimer)
          }
          // create a 13 second timer
          const typingTimer = setTimeout(clearAgentTyping, 13000, this)
          this.setState({ agentIsTyping: true, typingTimer: typingTimer })
          this.scrollToBottom()
        }
      })
      this.chatSession.onConnectionBroken((e) => {
        e.preventDefault()
        e.handlePressEnd()
      })

      this.sendHeartbeat() // send a heartbeat when chat first opens
      this.setState({ heartbeatInterval: setInterval(this.sendHeartbeat, 10 * 1000) }) // send a heartbeat every 10 seconds and save internval to state
    }).catch(error => {
      console.log('error: ' + error)
      this.setState({ state: 'failed' })
    })
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault()

      this.handleRefresh()
    })
  }

  setupBrowserActivity = () => {
    window.addEventListener('focus', () => {
      this.setState({ isWindowInFocus: true })
      console.log('In Focus')
    })
    window.addEventListener('blur', () => {
      this.setState({ isWindowInFocus: false })
      console.log('Out of Focus')
    })
    window.addEventListener('beforeunload', (e) => {
      if (this.state.state === 'chatting') {
        e.preventDefault()
        e.returnValue = ''
      }
    })
  }

  sendResponse = (ev) => {
    console.log(ev.currentTarget.value)
    this.chatSession.sendMessage({ message: ev.currentTarget.value, contentType: 'text/plain' })
    // this.setState({ clickedOption: true })
  }

  handleCustomerTyping = (e) => {
    this.setState({ message: e.target.value })
    this.chatSession.sendEvent({ contentType: 'application/vnd.amazonaws.connect.event.typing' })
  }

  handleSendMessage = (e) => {
    e.preventDefault()
    this.chatSession.sendMessage({
      message: this.state.message,
      contentType: 'text/plain'
    })
    this.setState({ message: '' })
  }

  chatSound = () => {
    const url = 'https://connect-9fc84bc2ca0c.s3.amazonaws.com/connect/itssc/chatalert.mp3'
    // eslint-disable-next-line
    const audio = new Audio(url)
    const promise = audio.play()
    if (promise !== undefined) {
      promise.then(_ => {
      // Autoplay started!
      }).catch(error => {
      // Autoplay was prevented.
        console.log(error)
      })
    }
  }

  handlePressEnd = (e) => {
    if (window.confirm('You are about to end the chat. Would you like to proceed?')) {
      e.preventDefault()
      clearInterval(this.state.heartbeatInterval)
      this.setState({ endPressed: true })
      if (this.state.agentInSession === false) {
        this.chatSession.disconnectParticipant()
      } else {
        this.chatSession.sendMessage({ message: '<sent to agent only> ' + this.state.name + ' has left the chat.', contentType: 'text/plain' })
      }
    }
  }

  handleAttach = (e) => {
    this.setState({ attachmentUploading: true })
    setTimeout(() => { this.scrollToBottom() }, 1)
    const files = Array.from(e.target.files)
    console.log(files)

    this.chatSession.sendAttachment({
      attachment: files[0]
    })
  }

  handleRefresh = () => {
    if (this.state.agentInSession === false) {
      this.chatSession.disconnectParticipant()
    } else {
      this.chatSession.sendMessage({ message: '<sent to agent only> ' + this.state.name + ' has left the chat.', contentType: 'text/plain' })
    }
  }

  handleReloadClicked = (e) => {
    window.location.reload(true)
  }

  showNotification = (e) => {
    if (this.state.notificationSupported === true) {
      let displayName
      let message
      const agentHas = e.name + ' has'
      const customerHas = 'You have'

      let personHas

      if (e.role === 'CUSTOMER') {
        personHas = customerHas
      }

      if (e.role === 'AGENT') {
        personHas = agentHas
      }
      if (e.role === 'SYSTEM') {
        displayName = 'ITS Service Center'
      } else {
        displayName = e.name
      }
      if (e.mainType === 'EVENT') {
        message = ''
        displayName = 'ITS Service Center'

        if (e.type.includes('joined')) {
          message = personHas + ' joined the chat.'
        } else if (e.type.includes('left')) {
          message = personHas + ' left the chat.'
        } else if (e.type.includes('succeed')) {
          message = 'The Transfer Succeeded.'
        } else if (e.type.includes('failed')) {
          message = 'The Transfer Failed.'
        } else if (e.type.includes('ended')) {
          message = 'The chat has ended.'
        }
      } else if (e.mainType === 'ATTACHMENT') {
        message = 'Attachment Recieved. Click to view on page.'
      } else {
        message = e.content
      }
      // eslint-disable-next-line
      var i = Notification.tag

      const options = {

        body: message,
        title: e.name,
        icon: 'https://connect-9fc84bc2ca0c.s3.amazonaws.com/connect/itssc/4help.png',
        tag: i

      }
      if (options.body.includes('<sent to agent only>') !== true || undefined) {
        this.chatSound()

        // eslint-disable-next-line
        var notification = new Notification(displayName, options)

        notification.onclick = function () {
          setTimeout(this.close(), 1 * 1000)
          window.focus()
          this.close()
        }
      }
    }
  }

  /* handleRejoinClicked = (e) => {
    this.setState({ rejoinPressed: true })
    this.setState({ endPressed: false })

    if (this.state.agentInSession === false && this.state.agentLeft === false) {
      this.setState({ rejoinPressed: true })
      this.handleStartChat(e)
    } else {
      this.chatSession.sendEvent({ contentType: 'application/vnd.amazonaws.connect.event.participant.joined' })

      this.chatSession.sendMessage({ message: '<sent to agent only> Customer has re-entered the chat.', contentType: 'text/plain' })
    }
  } */
  // heartbeat
  sendHeartbeat = () => {
    console.log('Sending heartbeat to instance %s and contact id %s', chatConfig.instanceId, this.chatSession.getChatDetails().initialContactId)
    axios.post(chatConfig.contactAttributeAPIGateway, {
      InstanceId: chatConfig.instanceId,
      InitialContactId: this.chatSession.getChatDetails().initialContactId
    })
  }

  render () {
    return (
      <div className='ChatApp' aria-live='polite'>
        {this.state.state === 'initial' &&
          <form onSubmit={this.handleStartChat}>
            <label htmlFor='firstname'>First name</label>
            <input
              id='firstname'
              type='text' name='name'
              value={this.state.name}
              aria-describedby='required-message'
              onChange={this.handleFormInputChange}
              required
            />
            <br />
            <label htmlFor='uniqname'>Email address</label>
            <input
              type='email' name='uniqname' value={this.state.uniqname} id='uniqname' required
              pattern='\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*'
              aria-describedby='required-message'
              onChange={this.handleFormInputChange}
            />
            <br />
            <input type='submit' value='Start Chat' />
            <br />

          </form>}

        {this.state.state === 'connecting' && <p>One moment, starting chat session...</p>}

        {this.state.state === 'failed' &&
          <p className='alert alert-warning'>Sorry, we were unable to connect to the chat service.
            <button onClick={this.handleReloadClicked}>Reinitiate Chat</button>
          </p>}

        {this.state.state === 'chatting' &&
          <div id='chat-live'>
            <div aria-live='polite'>
              <div id='chat-window'>
                {this.state.messages.map((message, index) =>
                  <Message
                    key={index}
                    attachmentName={message.attachmentName}
                    attachmentId={message.attachmentId}
                    attachUrl={message.attachUrl}
                    options={message.options}
                    mainType={message.mainType}
                    type={message.type}
                    role={message.role}
                    name={message.name}
                    content={message.content}
                    time={message.time}
                  />
                )}

                {this.state.agentIsTyping &&
                  <div className='agentTyping'>
                    <span className='label'>Agent is typing</span>
                  </div>}
                {this.state.attachmentUploading &&
                  <div className='attachmentUploading'>
                    <span className='label' id='attachLabel'>Sending...</span>
                  </div>}

              </div>

              {this.state.endPressed === false && this.state.customerNoResponse === false &&
                <div className='chat-form'>

                  <form name='chat-box' onSubmit={this.handleSendMessage} autoComplete='off'>
                    <div className='input-group'>
                      <div className='input-text'>
                        <input
                          type='text' id='message' value={this.state.message} aria-label='type your message here'
                          onChange={this.handleCustomerTyping}
                        />
                        <IconContext.Provider value={{ size: '1.7em' }}> <label htmlFor='attach'><BsPaperclip /></label></IconContext.Provider>
                        <input type='file' accept='.text, .txt, .pdf, .pjp, .jpg, .pjpg, .jfif, .jpeg, .png, .doc, .docx, .xls, .xlsx, .csv, .wav, .pptx, .ppt, .txt' id='attach' onChange={this.handleAttach} />
                      </div>

                    </div>

                  </form>

                  <div className='buttons'>
                    <input type='button' id='endChat' value='End Chat' onClick={this.handlePressEnd} />

                    <input type='button' id='sendChat' value='Send' onClick={this.handleSendMessage} />
                  </div>

                </div>}
              {this.state.endPressed === true && this.state.customerNoResponse === true &&
                <div className='chat-form' />}
            </div>
          </div>}

      </div>

    )
  }
}
